* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.main-background {
   background-image: url("./assets/image/mountain.png");
   height: 100vh;
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   display: flex;
   justify-content: center;
   align-items: center;
}

.card {
   background-color: rgba(0, 0, 0, 0.3);
   min-height: 420px;
   width: 500px;
   border-radius: 5px;
   padding: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
}

.myImg {
   width: 200px;
   border-radius: 50%;
   object-fit: cover;
}

.text {
   color: rgb(181, 180, 180);
   font-family: "Dosis", sans-serif;
   margin-top: 20px;
}

.name {
   font-weight: 700;
   font-size: 2rem;
}

.description {
   margin: 10px;
   font-size: 1.2rem;
}

.links a {
   font-size: 3rem;
   color: white;
   position: relative;
   bottom: -10px;
}

.author {
   text-align: right;
}

@media screen and (max-width: 1000px) {
   .card {
      min-width: 400px;
      max-width: 500px;
   }

   .main-background {
      align-items: start;
   }
   .card {
      position: relative;
      top: 100px;
   }
}
@media screen and (max-width: 400px) {
   .card {
      min-width: 400px;
   }

   .main-background {
      align-items: start;
   }
   .card {
      position: relative;
      top: 100px;
      /* min-height: 500px; */
   }
}
